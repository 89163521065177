import React from "react";
import { Helmet } from "react-helmet-async";
import { SEO_DATA, COMMON_SEO_TAGS } from "../../utils/constants-seo";

interface SEOHelmetProps {
  page: keyof typeof SEO_DATA;
  customTitle?: string;
  customDescription?: string;
}

export const SEOHelmet: React.FC<SEOHelmetProps> = ({ page, customTitle, customDescription }) => {
  const seoData = SEO_DATA[page];

  if (!seoData) {
    console.warn(`No SEO data found for page: ${page}`);
    return null;
  }

  const title = customTitle || seoData.title;
  const description = customDescription || seoData.description;

  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={COMMON_SEO_TAGS.robots} />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={`${COMMON_SEO_TAGS.baseUrl}/${page === "start" ? "" : page}`} />
      <meta property="og:title" content={seoData.ogTitle} />
      <meta property="og:description" content={seoData.ogDescription} />
      <meta property="og:image" content={`${COMMON_SEO_TAGS.baseUrl}${COMMON_SEO_TAGS.image}`} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`${COMMON_SEO_TAGS.baseUrl}/${page === "start" ? "" : page}`} />
      <meta property="twitter:title" content={seoData.ogTitle} />
      <meta property="twitter:description" content={seoData.ogDescription} />
      <meta property="twitter:image" content={`${COMMON_SEO_TAGS.baseUrl}${COMMON_SEO_TAGS.image}`} />

      {/* Additional SEO tags */}
      <meta name="keywords" content={seoData.keywords} />
      <meta name="author" content={COMMON_SEO_TAGS.author} />
      <meta name="language" content={COMMON_SEO_TAGS.language} />
      <meta name="geo.region" content={COMMON_SEO_TAGS.geoRegion} />
      <meta name="geo.placename" content={COMMON_SEO_TAGS.geoPlacename} />

      {/* Canonical URL */}
      <link rel="canonical" href={`${COMMON_SEO_TAGS.baseUrl}/${page === "start" ? "" : page}`} />
    </Helmet>
  );
};
